//  Region Import External Lib (e.g React, etc)
import CircularProgress from "@mui/material/CircularProgress";

import { CustomSpinnerProps } from "./CustomSpinner.types";

export default function CustomSpinner(props: CustomSpinnerProps) {
  return (
    <CircularProgress
      size={45}
      sx={{ color: 'primary' }}
      thickness={4}
      {...props}
    />
  );
}
