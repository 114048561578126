import {
  BrandsData,
  CompanyStreamConfigAlertData,
  OrdersData,
  OrdersDataPaging,
  OrdersParameter,
  OrdersPayload,
  PaymentConfigData,
  ProductsData,
  UsernamCheckData,
  UsernamCheckParameter,
} from "./customer.types";
import { createApiBase } from "config/api.config";
import { endPoint } from "constants/";

const companyQueries = createApiBase.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.query<OrdersDataPaging, OrdersParameter>({
      query: (params) => {
        return {
          url: endPoint.customer.orders,
          method: "GET",
          params,
        };
      },
    }),
    getUsernameCheck: build.query<UsernamCheckData, UsernamCheckParameter>({
      query: (params) => {
        return {
          url: endPoint.customer.usernameCheck,
          method: "GET",
          params,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getOrdersDetail: build.query<OrdersData, string>({
      query: (orderId) => {
        return {
          url: endPoint.customer.ordersDetail(orderId),
          method: "GET",
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    postOrders: build.mutation<any, OrdersPayload>({
      query: (data) => {
        return {
          url: endPoint.customer.orders,
          method: "POST",
          data,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getProducts: build.query<ProductsData[], string>({
      query: (brand) => {
        return {
          url: endPoint.customer.products,
          method: "GET",
          params: { brand },
        };
      },
      transformResponse: (res) => {
        const data = res.data as ProductsData[];
        return data;
      },
    }),
    getPaymentConfigs: build.query<PaymentConfigData[], number>({
      query: (amount) => {
        return {
          url: endPoint.customer.paymentConfigs,
          method: "GET",
          params: { amount },
        };
      },
      transformResponse: (res: any) => {
        const data = res.data as PaymentConfigData[];
        data.sort((a, b) => {
          let disabledFirstValue = false;
          if (a.amountPayment < a.minAmount) disabledFirstValue = true;
          else if (a.amountPayment > a.maxAmount) disabledFirstValue = true;

          let disabledSecondValue = false;
          if (b.amountPayment < b.minAmount) disabledSecondValue = true;
          else if (b.amountPayment > b.maxAmount) disabledSecondValue = true;

          if (
            (disabledFirstValue && disabledSecondValue) ||
            (!disabledFirstValue && !disabledSecondValue)
          ) {
            return a.amountPayment - b.amountPayment;
          } else if (disabledFirstValue) return 1;
          else if (disabledSecondValue) return -1;

          return 0;
        });
        return data;
      },
    }),
    getBrands: build.query<BrandsData[], void>({
      query: () => {
        return {
          url: endPoint.customer.brands,
          method: "GET",
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getBrandsDetail: build.query<BrandsData, string>({
      query: (brandId) => {
        return {
          url: endPoint.customer.brandsDetail(brandId),
          method: "GET",
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getCompanyStreamConfigAlert: build.query<
      CompanyStreamConfigAlertData,
      void
    >({
      query: () => {
        return {
          url: endPoint.customer.companyStreamConfigAlert,
          method: "GET",
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetOrdersQuery,
  useLazyGetUsernameCheckQuery,
  useLazyGetOrdersDetailQuery,
  useGetOrdersDetailQuery,
  usePostOrdersMutation,
  useLazyGetProductsQuery,
  useLazyGetPaymentConfigsQuery,
  useLazyGetBrandsQuery,
  useGetBrandsQuery,
  useLazyGetBrandsDetailQuery,
  useGetBrandsDetailQuery,
  useGetCompanyStreamConfigAlertQuery,
} = companyQueries;
