import { Dispatch, useEffect } from "react";

export default function useOutsideDiv(
  wrapperRef: any,
  profileRef: any,
  setIsShowDetail: Dispatch<boolean>
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!profileRef.current.contains(event.target)) {
          setIsShowDetail(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);
}
