import { ReactNode, Suspense } from "react";

import BasedComponent from "../based-component";
import GettingDataSpinner from "../getting-data-spinner";

export default function LoaderIcon({ children }: { children: ReactNode }) {
  /**
   * wrap your component with BasedComponent
   * if you think the component might be broken when doing rendering.
   *
   * If it is just a static component, it might not necessary to wrap your component
   * with BasedComponent
   */
  return (
    <BasedComponent>
      <Suspense
        fallback={
          <GettingDataSpinner hideMessage spinnerProps={{ size: 20 }} />
        }
      >
        {children}
      </Suspense>
    </BasedComponent>
  );
}
