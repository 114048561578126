import Box from "@mui/material/Box";

import { FailedLoadProps } from "./FailedLoad.types";
import "./FailedLoad.scss";
import CustomButton from "../custom-button";
import { FailedToLoad } from "../../assets/icons";
import { CenteredColumnFullBox } from "../../styles";

export default function FailedLoad({
  failedToLoadProps,
  ...props
}: FailedLoadProps) {
  return (
    <CenteredColumnFullBox>
      <FailedToLoad {...failedToLoadProps} />

      <Box className="failed-to-load-message">
        {props.message ?? "Failed to load"}
      </Box>

      {typeof props.onRefresh === "function" && (
        <CustomButton
          buttonProps={{
            onClick: props.onRefresh,
            className: "custom-button-failed-load",
          }}
        >
          Refresh
        </CustomButton>
      )}
    </CenteredColumnFullBox>
  );
}
