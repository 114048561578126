import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { CustomTypographyProps } from "types";

export const FormControlLabelCheckbox = styled(
  FormControlLabel
)<FormControlLabelProps>(({ theme, color }) => ({
  marginRight: "0px",
  ":hover": {
    ".MuiCheckbox-root": {
      ...(color === "primary" && {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      }),
      ...(color === "secondary" && {
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
      }),
      transition: "all 0.15s ease-in-out",
    },
  },
  "&.Mui-disabled": {
    cursor: "not-allowed",
    ".MuiSwitch-switchBase.Mui-checked": {
      // color: appColors.cultured,
    },
    ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      // backgroundColor: appColors.platinum,
      opacity: 1,
    },
  },
}));

export const CheckboxLabel = styled(Typography, {
  shouldForwardProp: (props) => props !== "disabled",
})<CustomTypographyProps & { disabled?: boolean }>(({ disabled }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
}));

export const CheckboxStyle = styled(Checkbox)<CheckboxProps>(
  ({ theme, color }) => ({
    padding: "0.5rem",
    marginRight: "0.25rem",
    ...(color === "primary" && {
      color: theme.palette.primary.main,
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    }),
    ...(color === "secondary" && {
      color: theme.palette.secondary.main,
      "&.Mui-checked": {
        color: theme.palette.secondary.main,
      },
    }),
  })
);
