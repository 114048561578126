import { format } from "date-fns";

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.valueOf());
}

export function formatDateddMMMyyyy(value?: string | Date | null) {
  const _date = value;

  if (!_date) return null;

  return `${
    isValidDate(new Date(_date)) ? format(new Date(_date), "dd MMM yyyy") : ""
  }`;
}

export function formatDateddMMMyyyyHHmm(value?: string | Date | null) {
  const _date = value;

  if (!_date) return null;

  return `${
    isValidDate(new Date(_date)) ? format(new Date(_date), "dd MMM yyyy, HH:mm") : ""
  }`;
}