import Box from "@mui/material/Box";

import { GettingDataSpinnerProps } from "./GettingDataSpinner.types";
import CustomSpinner from "../custom-spinner";
import { CenteredColumnBox } from "../../styles";

export default function GettingDataSpinner({
  spinnerProps,
  hideMessage,
  boxProps,
  ...props
}: GettingDataSpinnerProps) {
  return (
    <CenteredColumnBox {...boxProps}>
      <CustomSpinner {...spinnerProps} />

      {!hideMessage && (
        <Box className="spinner-text">{props.message || "Loading..."}</Box>
      )}
    </CenteredColumnBox>
  );
}
