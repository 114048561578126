import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7495 4.98047C7.88352 4.98047 4.74951 8.11448 4.74951 11.9805C4.74951 15.8465 7.88352 18.9805 11.7495 18.9805C15.6155 18.9805 18.7495 15.8465 18.7495 11.9805C18.7495 8.11448 15.6155 4.98047 11.7495 4.98047ZM2.74951 11.9805C2.74951 7.00991 6.77895 2.98047 11.7495 2.98047C16.7201 2.98047 20.7495 7.00991 20.7495 11.9805C20.7495 16.951 16.7201 20.9805 11.7495 20.9805C6.77895 20.9805 2.74951 16.951 2.74951 11.9805Z"
        fill={props?.fill ?? "#fefefe"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6923 16.9238C17.0828 16.5332 17.716 16.5332 18.1065 16.9238L22.4565 21.2738C22.847 21.6643 22.847 22.2974 22.4565 22.688C22.066 23.0785 21.4328 23.0785 21.0423 22.688L16.6923 18.338C16.3018 17.9474 16.3018 17.3143 16.6923 16.9238Z"
        fill={props?.fill ?? "#fefefe"}
      />
    </SvgIcon>
  );
};

export default SearchIcon;
