import loadable from "@loadable/component";
import { Navigate, Route, Routes } from "react-router-dom";

import { LoaderPage } from "components";
import { menuUrl } from "constants/";

const Home = loadable(() => import("pages/home"), { fallback: <LoaderPage /> });
const CekPesanan = loadable(() => import("pages/cek-pesanan"), {
  fallback: <LoaderPage />,
});
const DetailBrand = loadable(() => import("pages//detail-brand"), {
  fallback: <LoaderPage />,
});
const RiwayatTransaksi = loadable(() => import("pages/riwayat-transaksi"), {
  fallback: <LoaderPage />,
});

const routes = (
  <Routes>
    <Route path={menuUrl.base} element={<Home />} />
    <Route path={menuUrl.cekPesanan} element={<CekPesanan />} />
    <Route path={menuUrl.cekPesananDetail} element={<CekPesanan />} />
    <Route path={menuUrl.detailBrand} element={<DetailBrand />} />
    <Route path={menuUrl.riwayatPesanan} element={<RiwayatTransaksi />} />
    
    <Route path="*" element={<Navigate replace to={menuUrl.base} />} />
  </Routes>
);

export default routes;
