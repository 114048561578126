//  Region Import External Lib (e.g React, etc)
import { Component } from 'react';

//  Region Import Constants

//  Region Import Interfaces
import { BasedComponentProps, BasedComponentState } from './BasedComponent.types';

//  Region Import Redux Related/Hooks

//  Region Import Utility Function

//  Region Import Components
import FailedLoad from '../failed-load';
import ComponentLoader from '../component-loader';

//  Region Import Assets

//  Region Import Style

/**
 * @description
 * this BasedComponent handling error boundary for component that might break
 * due to unmatch data types from external resources (e.g. API) when doing rendering.
 *
 * It is recommended to wrap other component with this BasedComponent if the component
 * met above condition. Static components are not required to wrap with this BasedComponent.
 */
export default class BasedComponent extends Component<BasedComponentProps, BasedComponentState> {
  constructor(props: BasedComponentProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error.message);

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // try re-render child
  refresh = () => this.setState({ hasError: false });

  render() {
    const { hasError } = this.state;

    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <FailedLoad onRefresh={this.refresh} failedToLoadProps={{ width: '25%', height: '25%' }} />
      );
    }

    return <ComponentLoader>{children}</ComponentLoader>;
  }
}
