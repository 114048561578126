//  Region Import External Lib (e.g React, etc)
import { Component } from "react";

//  Region Import Constants

//  Region Import Interfaces
import { BasedPagesProps, BasedPagesState } from "./BasedPages.types";

//  Region Import Redux Related/Hooks

//  Region Import Utility Function

//  Region Import Components
import PagesContainer from "components/pages-container";
import FailedLoadPages from "components/failed-load-pages";

//  Region Import Assets

//  Region Import Style

/**
 * @description
 * this BasedPages handling error boundary for component that might break
 * due to unmatch data types from external resources (e.g. API) when doing rendering.
 *
 * It is recommended to wrap other component with this BasedPages if the component
 * met above condition. Static components are not required to wrap with this BasedPages.
 */
export default class BasedPages extends Component<
  BasedPagesProps,
  BasedPagesState
> {
  constructor(props: BasedPagesProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error.message);

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // try re-render child
  refresh = () => this.setState({ hasError: false });

  render() {
    const { hasError } = this.state;

    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <PagesContainer>
          <FailedLoadPages onRefresh={this.refresh} />
        </PagesContainer>
      );
    }

    return <PagesContainer>{children}</PagesContainer>;
  }
}
