import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

export const BoxFullHeightWidth = styled(Box)<BoxProps>(() => ({
  height: "100vh",
  width: "100vw",
}));

export const CenteredBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LeftBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const LeftColumnBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
}));

export const RightBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const LeftTopBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
}));

export const CenteredColumnBox = styled(CenteredBox)<BoxProps>(() => ({
  flexDirection: "column",
  height: "100%",
}));

export const CenteredColumnFullBox = styled(CenteredColumnBox)<BoxProps>(
  () => ({
    width: "100%",
  })
);

export const FlexItemCenter = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
}));

export const FlexItemCenterWrap = styled(FlexItemCenter)<BoxProps>(() => ({
  flexWrap: "wrap",
}));

export const FlexSpaceBetween = styled(FlexItemCenter)<BoxProps>(() => ({
  justifyContent: "space-between",
}));

export const BoxFlexEnd = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const BoxHeight100Percent = styled(Box)<BoxProps>(() => ({
  height: "100%",
}));

export const FlexReverse = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
}));
