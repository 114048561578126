import { PagesContainerBox } from "./PagesContainer.styles";
import { PagesContainerProps } from "./PagesContainer.types";

/**
 * Use this container only if the pages not displayed under modal.
 * This is because the height is calculated based on the top bar
 */
export default function PagesContainer(props: PagesContainerProps) {
  return (
    <PagesContainerBox {...props.pagesContainerBoxProps}>
      {props.children}
    </PagesContainerBox>
  );
}
