export const transformUnderscoreToUrl = (value: string) => {
  const newValue = value
    .split("_")
    .map((element: string) => {
      return element.toLowerCase();
    })
    .join("-");

  return newValue;
};

export const transformUrlToUnderscore = (value: string) => {
  const newValue = value
    .split("-")
    .map((element: string) => {
      return element.toUpperCase();
    })
    .join("_");

  return newValue;
};
