import { Player } from "@lottiefiles/react-lottie-player";

import "./LoaderPage.scss";
import { LoaderPage as loaderPage } from "assets/icons";
import { BasedPages } from "components";
import { CenteredColumnBox } from "styles";

function LoaderPage() {
  return (
    <BasedPages>
      <CenteredColumnBox sx={{minHeight: 'calc(100vh - 10rem)'}}>
        <Player
          src={loaderPage}
          className="loader-gif"
          loop
          autoplay
          speed={0.7}
        />
      </CenteredColumnBox>
    </BasedPages>
  );
}

export default LoaderPage;
