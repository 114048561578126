import { handleError } from "utils/handleToast";

export const responseErrorHandler = (error: any) => {
  console.log("error di responseErrorHandler", error);
  if (!error?.message) return;

  const errorObject = JSON.parse(error.message);
  console.log("errorObject : ", errorObject);
  if (errorObject?.message?.length > 0) {
    handleError([errorObject.message]);
  } else if (errorObject.data) {
    handleError(errorObject?.data?.errors?.messages ?? ["Error"]);
  }
};
