import { convertToCurrency } from "utils";
import { ProductName, CustomPaper, Price } from "./CustomCardProduct.styles";
import { CustomCardProductProps } from "./CustomCardProduct.types";

function CustomCardProduct({
  productName,
  price,
  productId,
  onClick,
  paperProps
}: CustomCardProductProps) {
  return (
    <CustomPaper onClick={() => onClick(productId)} {...paperProps}>
      <ProductName>{productName}</ProductName>
      <Price>{convertToCurrency(price)}</Price>
    </CustomPaper>
  );
}

export default CustomCardProduct;
