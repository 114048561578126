export const genshinServerOptions: { value: string; label: string }[] = [
  { value: "|os_asia", label: "Asia" },
  { value: "|os_america", label: "America" },
  { value: "|os_euro", label: "Europe" },
  { value: "|os_cht", label: "TW, HK, MO" },
];

export const ragnarokEternalLoveServerOptions: {
  value: string;
  label: string;
}[] = [
  { value: "|Eternal Love", label: "Eternal Love" },
  { value: "|Midnight Party", label: "Midnight Party" },
  { value: "|Memory of Faith", label: "Memory of Faith" },
  { value: "|Valhalla Glory", label: "Valhalla Glory" },
];

export const harryPotterServerOptions: { value: string; label: string }[] = [
  { value: "+Thunderbird", label: "Thunderbird" },
  { value: "+不死鳥", label: "不死鳥" },
  { value: "+니플러", label: "니플러" },
  { value: "+Ashwinder", label: "Ashwinder" },
  { value: "+Sphinx", label: "Sphinx" },
  { value: "+Rougarou", label: "Rougarou" },
  { value: "+Erumpent", label: "Erumpent" },
  { value: "+ユニコーン", label: "ユニコーン" },
];

export const honkaiStarRailServerOptions: { value: string; label: string }[] = [
  { value: "|os_asia", label: "Asia" },
  { value: "|os_usa", label: "America" },
  { value: "|os_euro", label: "Europe" },
  { value: "|os_cht", label: "TW, HK, MO" },
];
