//  Region Import External Lib (e.g React, etc)
import { useField } from "formik";

//  Region Import Constants

//  Region Import Interfaces
import { CustomCheckboxProps } from "./CustomCheckbox.types";

//  Region Import Redux Related/Hooks

//  Region Import Utility Function

//  Region Import Components
import BasedComponent from "../based-component";

//  Region Import Assets

//  Region Import Style
import {
  FormControlLabelCheckbox,
  CheckboxLabel,
  CheckboxStyle,
} from "./CustomCheckbox.styles";

export default function CustomCheckbox({
  checkboxProps,
  name,
  labelPlacement = "end",
  disabled,
  formControlLabelProps,
  label,
  ...rest
}: CustomCheckboxProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField({ name, ...rest });

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(checked);

    if (typeof checkboxProps?.onChange !== "function") return;

    checkboxProps.onChange(event, checked);
  };

  return (
    <BasedComponent>
      <FormControlLabelCheckbox
        disabled={disabled}
        label={<CheckboxLabel disabled={disabled}>{label}</CheckboxLabel>}
        labelPlacement={labelPlacement}
        control={
          <CheckboxStyle {...checkboxProps} {...field} onChange={onChange} />
        }
        {...(checkboxProps?.color && { color: checkboxProps.color })}
        {...formControlLabelProps}
        checked={field.value}
      />
    </BasedComponent>
  );
}
