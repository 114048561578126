import { ThemeOptions, createTheme } from "@mui/material";
import "./fonts.scss";

const themeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: "#e5c5ca",
      light: "#fdedef",
    },
    secondary: {
      main: "#fbcbcb",
    },
    text: {
      primary: "#272529",
      secondary: "#df929c",
      disabled: "#d3d3d3",
    },
    background: {
      default: "#8d82aa",
      paper: "#fcd6e3",
    },
    info: {
      main: "#cfd2d4",
    },
    warning: {
      main: "#FFD027",
    },
    success: {
      main: "#38ee78",
    },
    error: {
      main: "#fb604f",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Raleway-Regular"].join(","),
    h1: {
      fontFamily: ["Raleway-SemiBold"].join(","),
      fontSize: "2rem",
    },
    h2: {
      fontFamily: ["Raleway-SemiBold"].join(","),
      fontSize: "1.5rem",
    },
    h3: {
      fontFamily: ["Raleway-Medium"].join(","),
      fontSize: "1.25rem",
    },
    h4: {
      fontFamily: ["Raleway-Medium"].join(","),
      fontSize: "1rem",
    },
    h5: {
      fontFamily: ["Raleway-Regular"].join(","),
      fontSize: "0.875rem",
    },
    h6: {
      fontFamily: ["Raleway-Regular"].join(","),
      fontSize: "0.6875rem",
    },
  },
};

const theme = createTheme(themeSettings);

export default theme;
