import { FailedLoadPagesProps } from "./FailedLoadPages.types";
import { CenteredColumnFullBox } from "../../styles";
import {
  FailedToLoadMessage,
  ImageFailedToLoad,
} from "./FailedLoagPages.styles";
import { CustomButton } from "components";

export default function FailedLoadPages(props: FailedLoadPagesProps) {
  return (
    <CenteredColumnFullBox>
      <ImageFailedToLoad
        src={process.env.PUBLIC_URL + "/assets/images/error.png"}
        alt="image-failed=to=load"
      />

      <FailedToLoadMessage>
        {props.message ?? "Terjadi masalah jaringan :("}
      </FailedToLoadMessage>

      {typeof props.onRefresh === "function" && (
        <CustomButton
          buttonProps={{
            onClick: props.onRefresh,
          }}
        >
          Refresh
        </CustomButton>
      )}
    </CenteredColumnFullBox>
  );
}
