import toast from "react-hot-toast";

export const handleError = (messages: Array<string>) => {
  messages.map((message: string) =>
    toast.error(message, {
      position: "top-center",
      duration: 3000,
      style: {
        padding: "0.5rem 1.5rem",
        color: "#C03221",
        borderRadius: '1.25rem',
      },
      iconTheme: {
        primary: "#C03221",
        secondary: "#fefefe",
      },
    })
  );
};

export const handleSuccess = (message: string) => {
  toast.success(message, {
    position: "top-center",
    duration: 3000,
    style: {
      padding: "0.5rem 1.5rem",
      color: "#23C35A",
      borderRadius: '1.25rem',
    },
    iconTheme: {
      primary: "#23C35A",
      secondary: "#fefefe",
    },
  });
};
