export function vh(sizeInPx: number) {
  const sizeInVh = (sizeInPx * 100) / 1080;

  return `${sizeInVh}vh`;
}

export function vw(sizeInPx: number) {
  const sizeInVw = (sizeInPx * 100) / 1440;

  return `${sizeInVw}vw`;
}

export function vwMobile(sizeInPx: number) {
  const sizeInVw = (sizeInPx * 100) / 414;

  return `${sizeInVw}vw`;
}

export function vwTab(sizeInPx: number) {
  const sizeInVw = (sizeInPx * 100) / 840;

  return `${sizeInVw}vw`;
}

export function rem(sizeInPx: number) {
  const sizeInRem = sizeInPx / 16;

  return `${sizeInRem}rem`;
}
