import { Player } from "@lottiefiles/react-lottie-player";
import Box from "@mui/material/Box";

import "./LoaderPageFetch.scss";
import { LoaderPageFetch as loaderPageFetch } from "assets/icons";

function LoaderPageFetch() {
  return (
    <Box className="background-loader">
      <Player
        src={loaderPageFetch}
        className="loader-gif"
        loop
        autoplay
        // speed={0.7}
      />
    </Box>
  );
}

export default LoaderPageFetch;
